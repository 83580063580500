<template>
    <v-list-item v-if="item.productID" :dense="dense" two-line>
        <v-list-item-avatar v-if="showImages" class="my-auto">
            <v-img :src="productLogoURL(item.productID)">
                <template v-slot:placeholder>
                    <v-icon class="primary--text" size="40">mdi-cube-outline</v-icon>
                </template>
            </v-img>
        </v-list-item-avatar>
        <v-list-item-content class="my-0 py-0">
            <v-list-item-title class="my-2">{{ item.description }}</v-list-item-title>
            <v-list-item-subtitle v-if="showSupplier && item.product != null && item.product.companyAccountID != null">
                <BT-Entity
                    itemText="companyName"
                    :itemValue="item.product.companyAccountID"
                    navigation="public-companies"
                    single
                    useLocalCache />
            </v-list-item-subtitle>
            <v-list-item-subtitle>
                <v-text-field
                    v-if="canEdit"
                    class="ma-0 pa-0"
                    color="teal"
                    dense
                    @input="updateQuantity"
                    outlined
                    placeholder="Quantity"
                    :rules="quantityRules"
                    single-line
                    style="max-width: 250px;"
                    type="number"
                    v-model.number="item.quantity">
                    <template v-slot:prepend>
                        <v-icon tabIndex="-1" @click.stop="remove(1)">mdi-minus</v-icon>
                    </template>
                    <template v-slot:append-outer>
                        <v-icon tabIndex="-1" @click.stop="add(1)">mdi-plus</v-icon>
                    </template>
                </v-text-field>
                <strong v-else>QTY: {{ item.quantity | toDisplayNumber }}</strong>
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="showPricing" class="my-auto">
            <v-list-item-action-text>
                @{{ item.unitPrice | toCurrency }}ea
            </v-list-item-action-text>
            {{ item.unitPrice * item.quantity | toCurrency}}
        </v-list-item-action>
    </v-list-item>
    <v-list-item v-else :dense="dense">
        <v-list-item-content>
            <v-spacer />
            <v-list-item-title>{{ item.description }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.productID" class="teal--text"><strong>QTY: {{ item.quantity | toDisplayNumber }}</strong></v-list-item-subtitle>                        
        </v-list-item-content>
        <v-list-item-action v-if="showPricing">{{ item.unitPrice * item.quantity | toCurrency }}</v-list-item-action>
    </v-list-item>
</template>

<script>
import { getUnitPrice, totalAmount } from '~tools/helpers.js';

export default {
    name: 'Ordering-Line-Item',
    data: () => {
        return {
            quantityRules: [v => v >= 0 || 'Must be 0 or more' ],
            incrementVal: 1
        }
    },
    props: {
        item: null,
        canEditPrice: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: true
        },
        showImages: {
            type: Boolean,
            default: false
        },
        showPricing: {
            type: Boolean,
            default: true
        },
        showSupplier: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.reset();
    },
    watch: {
        item() {
            this.reset();
        }
    },
    methods: {
        reset() {
            this.quantityRules = [v => v >= 0 || 'Must be 0 or more'];

            if (this.item.data) {
                var incRule = this.item.data.rules.find(x => x.rule === 'QuantityInIncrements');
                if (incRule) {
                    this.incrementVal = incRule.quantity;
                    this.quantityRules.push(v => v % this.incrementVal == 0 || 'Order in increments of ' + this.incrementVal);
                }

                var moqRule = this.item.data.rules.find(x => x.rule === 'QuantityMoreThan');
                if (moqRule != null) {
                    this.quantityRules.push(v => v == 0 || v >= moqRule.quantity || `Minimum order quantity is ${moqRule.quantity}`);
                }
            }
        },
        update() {
            if (Number.isNaN(this.item.quantity)) {
                this.item.quantity = 0;
            }
            
            this.item.unitPrice = getUnitPrice(this.item.data.unitPriceIncrements, this.item.quantity);
            this.item.amountTotal = totalAmount(this.item); //this.item.quantity * this.item.unitPrice;                        
            this.$emit('input', this.item);
            this.$emit('change', this.item);
        },
        add(inc) {
            var rule = this.item.data.rules?.find(x => x.rule === 'QuantityInIncrements');
            var qtyToAdd = inc;
            
            if (rule != null && rule.quantity > 0) {
                qtyToAdd = qtyToAdd * rule.quantity;

                if (this.item.quantity % rule.quantity > 0) {                    
                    qtyToAdd = (rule.quantity - (this.item.quantity % rule.quantity));
                }
            }

            this.item.quantity = parseFloat(this.item.quantity) + parseFloat(qtyToAdd);            

            this.update();
        },
        remove(inc) {
            var rule = this.item.data.rules?.find(x => x.rule === 'QuantityInIncrements');
            var qtyToAdd = inc;

            if (rule != null && rule.quantity > 0) {
                qtyToAdd = qtyToAdd * rule.quantity;

                if (this.item.quantity % rule.quantity > 0) {
                    qtyToAdd = this.item.quantity % rule.quantity;
                }
            }            

            this.item.quantity = parseFloat(this.item.quantity) - parseFloat(qtyToAdd);
            
            if (this.item.quantity < 0) {
                this.item.quantity = 0;
            }

            this.update();
        },
        updateQuantity(val) {            
            this.item.quantity = parseFloat(val);
            this.update();
        }
    }
}
</script>